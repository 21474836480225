module.exports = {
  siteTitle: 'KG Designs', // <title>
  manifestName: 'Karina Gutierrez UX',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-spectral/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Karina Gutierrez',
  subHeading: 'UX/UI Designer and Anthropologist',
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-linkedin',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/karina-gutierrez6294/',
    },
    {
      style: 'brands',
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/karinag6294/',
    },
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:contactus@kgutierrezux.com',
    },
  ],
};
